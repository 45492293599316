<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="11"
        >
          <div
            v-if="hasPermissionToUpdate"
          >
            <b-row
              v-if="isViewMode"
            >
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>

                  <b-dropdown-item
                    id="bnt-edit"
                    :disabled="!hasPermissionToUpdate"
                    @click="enableEdition()"
                  >
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">Editar</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    :disabled="!hasPermissionToDelete"
                    @click="showConfirmOrCancelDelete(dataId)"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>

          <validation-observer
            #default="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <b-card
                no-body
                class="pb-2"
              >
                <b-card-title
                  class="mt-2 ml-2 mb-0"
                >
                  <span class="d-inline">Cadastro de Plataforma</span>
                </b-card-title>
                <hr>
                <b-row
                  class="mx-2"
                >
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="name"
                      :rules="{
                        required: true,
                      }"
                    >
                      <b-form-group
                        label="Plataforma"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="data.name"
                          name="platform"
                          placeholder="Plataforma"
                          :state="isViewMode ? null : getValidationState(validationContext)"
                          :readonly="isViewMode"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <validation-provider
                      #default="validationContext"
                      name="link"
                      :rules="{
                        required: false,
                      }"
                    >
                      <b-form-group
                        label="Link"
                        label-for="link"
                      >
                        <b-input-group>
                          <b-input-group-prepend
                            v-if="isViewMode"
                            :class="{
                              'disabledEdition' : isViewMode
                            }"
                            is-text
                          >
                            <b-link
                              :href="isViewMode ? getLink(data.link) : ''"
                              target="_blank"
                              rel="noreferrer"
                              :disabled="!isViewMode"
                            >
                              <span>
                                <svg-icon
                                  type="mdi"
                                  :path="mdiLinkBoxVariant"
                                />
                              </span>
                            </b-link>
                          </b-input-group-prepend>
                          <b-form-input
                            id="link"
                            v-model="data.link"
                            name="link"
                            placeholder="Link"
                            :state="isViewMode ? null : getValidationState(validationContext)"
                            :readonly="isViewMode"
                          />
                        </b-input-group>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
              <b-card
                v-if="!isCreateMode"
                id="idCardLogins"
                no-body
                :class="isViewMode ? 'pb-2' : ''"
              >
                <b-table
                  class="position-relative mb-0"
                  thead-class="itemTableHeader"
                  primary-key="id"
                  responsive
                  show-empty
                  striped
                  hover
                  empty-text="Não há logins cadastrados."
                  :items="data.logins_set"
                  :fields="tableColumns"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="isSortDirDesc"
                  @row-clicked="showLogin"
                >
                  <template #cell(company)="field">
                    <span class="font-weight-bold text-nowrap">
                      {{ field.item.company_set.corporate_name }}
                    </span>
                  </template>

                  <template #cell(login)="field">
                    <span class="font-weight-bold text-nowrap">
                      {{ field.item.login }}
                    </span>
                  </template>

                  <template #cell(password)="field">
                    <span class="font-weight-bold text-nowrap">
                      {{ field.item.password }}
                    </span>
                  </template>

                  <template #cell(received_email)="field">
                    <span
                      v-if="field.item.received_email"
                      class="font-weight-bold text-nowrap"
                    >
                      SIM
                    </span>
                    <span
                      v-else
                      class="font-weight-bold text-nowrap"
                    >
                      NÃO
                    </span>
                  </template>

                  <template #cell(field_actions)="field">
                    <div>
                      <b-button
                        class="btn-icon rounded-circle"
                        variant="flat-info"
                        size="md"
                        @click="showConfirmOrCancelDeleteLogin(field.item.id)"
                      >
                        <svg-icon
                          type="mdi"
                          size="18"
                          :path="mdiTrashCanOutline"
                        />
                      </b-button>
                    </div>
                  </template>
                </b-table>
                <b-row v-if="!isViewMode">
                  <b-col
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      id="btnAddLogin"
                      class="btn-icon rounded-circle m-1"
                      variant="primary"
                      size="md"
                      title="Adicionar Login"
                      @click="showLogin()"
                    >
                      <svg-icon
                        type="mdi"
                        :path="mdiPlus"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
              <b-row
                class="mt-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    v-if="!isViewMode"
                    id="btn-save"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    v-if="!isViewMode"
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    @click="disableEdition"
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    v-if="isViewMode"
                    id="itemCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to=" { name: 'bidding-platform' } "
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </div>
    <validation-observer
      v-slot="{ handleSubmit, invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="idModalLogin"
        ref="modalLogin"
        no-close-on-backdrop
        no-close-on-esc
        centered
        size="lg"
        cancel-variant="outline-primary"
        :ok-disabled="invalid"
        :hide-footer="isViewMode"
        @ok="handleSubmit(saveLogin)"
        @cancel="cancel()"
        @hidden="cancel()"
      >
        <template #modal-title>
          Dados de Login e Senha
        </template>
        <template #modal-cancel>
          Cancelar
        </template>
        <template #modal-ok>
          Salvar
        </template>
        <b-form
          class="mt-1"
        >
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <company-select
                id="company"
                v-model="login.company_set"
                :readonly="isViewMode"
                :required-field="true"
                @ready="syncLoad.ready('company')"
              />
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="login"
                :rules="{
                  required: false,
                }"
              >
                <b-form-group
                  label="Login"
                  label-for="login"
                >
                  <b-form-input
                    id="login"
                    v-model="login.login"
                    name="login"
                    placeholder="Login"
                    :state="isViewMode ? null : getValidationState(validationContext)"
                    :readonly="isViewMode"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="password"
                :rules="{
                  required: false,
                }"
              >
                <b-form-group
                  label="Senha"
                  label-for="password"
                >
                  <b-form-input
                    id="password"
                    v-model="login.password"
                    name="password"
                    placeholder="Senha"
                    :state="isViewMode ? null : getValidationState(validationContext)"
                    :readonly="isViewMode"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Anotações"
                label-for="observation"
              >
                <b-form-textarea
                  id="observation"
                  v-model="login.observation"
                  class="loginObservation"
                  trim
                  :placeholder="'Anotações'"
                  :readonly="isViewMode"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              class="d-flex align-items-center"
            >
              <b-form-group
                label="E-mail Recebido?"
                label-for="received_email"
              >
                <b-form-checkbox
                  v-model="login.received_email"
                  class="mt-50"
                  switch
                  :disabled="isViewMode"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="isViewMode"
            class="mt-1 mb-50"
          >
            <b-col
              class="d-flex justify-content-end"
            >
              <b-button
                v-if="isViewMode"
                id="loginRowBackButton"
                variant="outline-primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                @click="hideModal()"
              >
                Voltar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import log from 'loglevel'
import {
  BCard,
  BCardTitle,
  BTable,
  BRow,
  BCol,
  BForm,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BButton,
  BDropdown,
  BDropdownItem,
  BLink,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  required,
} from '@validations'

import {
  useToast,
} from 'vue-toastification/composition'

import {
  mdiLinkBoxVariant,
  mdiPlus,
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import CompanySelect from '@/components/company/CompanySelect.vue'
import syslic from '@/syslic'
import router from '@/router'
import SyncLoad from '@/components/utils/syncLoad'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import errorDetector from '@/components/utils/errorDetector'

export default {
  components: {
    BCard,
    BCardTitle,
    BTable,
    BRow,
    BCol,
    BForm,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupPrepend,
    BFormInvalidFeedback,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BDropdown,
    BDropdownItem,
    BLink,
    SpinLoader,
    SvgIcon,
    CompanySelect,
  },
  directives: {
    Ripple,
  },
  data() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.data)

    const {
      refFormObserverLogin,
      getValidationStateLogin,
    } = formValidation(this.login)

    let isViewMode = true
    let isCreateMode = false

    if (router.currentRoute.path.match(/.*\/edit\/.*/)
        || router.currentRoute.path.match(/.*\/create/)) {
      isViewMode = false
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const data = {
      id: '',
      link: '',
      logins: [],
      logins_set: [],
      name: '',
    }

    const login = {
      company: '',
      company_set: '',
      login: '',
      password: '',
      platform: '',
      received_email: false,
    }

    const isAdd = false

    const sortBy = 'name'
    const isSortDirDesc = false

    const tableColumns = [
      {
        key: 'company',
        label: 'Empresa',
      },
      {
        key: 'login',
        label: 'Login',
      },
      {
        key: 'password',
        label: 'Senha',
      },
      {
        key: 'received_email',
        label: 'E-mail Recebido?',
      },
      {
        key: 'field_actions',
        label: '',
        thStyle: { width: '5%' },
      },
    ]

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('platform')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    return {
      required,
      isViewMode,
      isCreateMode,
      refFormObserver,
      getValidationState,
      refFormObserverLogin,
      getValidationStateLogin,
      text_error: '',
      data,
      dataId: null,
      login,
      isAdd,
      sortBy,
      isSortDirDesc,
      tableColumns,
      isLoaded,
      syncLoad,
    }
  },
  computed: {
    hasPermissionToUpdate() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_edit
    },
    hasPermissionToDelete() {
      const profile = syslic.authentication.getProfile()
      return profile.get_permissions_for_modules.bidding_settings.can_delete
    },
  },
  watch: {
    'login.company_set': function updateCompany(value) {
      if (value) {
        this.login.company = value.id
        this.login.company_set = value
      } else {
        this.login.company = ''
        this.login.company_set = ''
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const idToUpdate = router.currentRoute.params.id
      this.dataId = router.currentRoute.params.id
      if (idToUpdate !== undefined) {
        syslic
          .bidding
          .platform
          .fetchPlatform(router.currentRoute.params.id)
          .then(response => {
            this.data = response.data
            this.login.platform = this.data.id
            this.syncLoad.ready('platform')
          })
          .catch(err => {
            if (err.status === 404) {
              router.push({ name: 'error-404' })
            }
            this.syncLoad.ready('platform')
          })
      } else {
        this.syncLoad.ready('platform')
      }
    },
    createPlatform() {
      syslic
        .bidding
        .platform
        .addPlatform(this.data)
        .then(response => {
          this.dataId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Plataforma adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          const textError = errorDetector(error.data, [
            {
              error: 'duplicate key value violates unique constraint',
              message: 'Já existe uma plataforma com este nome, não é possivel adicionar uma plataforma com nome repetido.',
            },
            {
              error: '',
              message: 'Não foi possível adicionar esta plataforma, por favor entre em contato com o administrador do sistema.',
            },
          ])
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar plataforma.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updatePlatform() {
      syslic
        .bidding
        .platform
        .updatePlatform(this.data.id, this.data)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Plataforma atualizada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.disableEdition()
        })
        .catch(error => {
          const textError = errorDetector(error.data, [
            {
              error: 'duplicate key value violates unique constraint',
              message: 'Já existe uma plataforma com este nome, não é possivel atualizar uma plataforma com nome repetido.',
            },
            {
              error: '',
              message: 'Não foi possível atualizar esta plataforma, por favor entre em contato com o administrador do sistema.',
            },
          ])

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar a plataforma.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createPlatform()
      } else {
        this.updatePlatform()
      }
    },
    deletePlatform(id) {
      syslic
        .bidding
        .platform
        .deletePlatform(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Plataforma removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'bidding-platform' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover a plataforma do servidor, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta plataforma é referenciada em alguma licitação, por este motivo não será possível exclui-la.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover plataforma.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete(item) {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover essa plataforma?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deletePlatform(item)
          }
        })
    },
    deleteLogin(id) {
      syslic
        .bidding
        .platform
        .deleteLogin(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Login removido com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.fetchData()
        })
        .catch(() => {
          const textError = 'Não foi possível remover o login do servidor, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover login.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDeleteLogin(itemId) {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover esse login?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteLogin(itemId)
          }
        })
    },
    saveLogin() {
      if (this.isAdd) {
        this.addLogin()
      } else {
        this.updateLogin()
      }

      this.isAdd = true
    },
    addLogin() {
      syslic
        .bidding
        .platform
        .addLogin(this.login)
        .then(response => {
          this.login = response.data
          this.fetchData()
          this.isAdd = false
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Login adicionado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          log.debug('ERROR', error)
          this.text_error = 'Não foi possível adicionar o login, por favor entre em contato com o administrador do sistema.'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar login.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    updateLogin() {
      syslic
        .bidding
        .platform
        .updateLogin(this.login.id, this.login)
        .then(() => {
          this.fetchData()
          this.isAdd = false
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Login atualizado com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualizar o login.',
              text:
                'Não foi possível atualizar o login, por favor entre em contato com o administrador do sistema.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    cancel() {
      this.login = {
        company: '',
        company_set: '',
        login: '',
        password: '',
        platform: this.data.id,
        received_email: false,
      }
    },
    enableEdition() {
      this.isViewMode = false
      router.push({ name: 'bidding-platform-edit' })
    },
    disableEdition() {
      if (this.isCreateMode) {
        router.push({ name: 'bidding-platform' })
      } else {
        router.push({
          name: 'bidding-platform-detail',
          params: {
            id: this.dataId,
          },
        })
      }

      this.isViewMode = true
      this.isCreateMode = false
    },
    showLogin(login) {
      this.isAdd = true
      if (login) {
        this.login = JSON.parse(JSON.stringify(login))
        this.isAdd = false
      }
      this.$refs.modalLogin.show()
    },
    hideModal() {
      this.$refs.modalLogin.hide()
    },
    getLink(url) {
      let path = url
      if (!((url.includes('http://')) || (url.includes('https://')))) {
        path = `https://${path}`
      }
      return path
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiLinkBoxVariant,
      mdiPlus,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.disabledEdition .input-group-text{
  background-color: #efefef;
}

input::placeholder {
  color: #b9b9c3 !important;
}

.disabledEdition .input-group-text{
  background-color: #EAEEF5;
  border: 0;
}

#idModalLogin .vc-slider {
  width: 100% !important;
}

#idModalLogin .modal-footer {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: end;

  .btn{
    min-width: 107px;
  }
}

#idCardLogins .itemTableHeader th {
  color: $primary;
  background-color: #fff;
  border-radius: 0.428rem;
}

.loginObservation {
  min-height: 8.25rem;
}

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}
</style>
